

<template>
    <!-- <div>场馆档期</div> -->
    <section class="wh_container">
        <div class="wh_content_all">
            <div class="wh_top_changge">
                <p class="wh_top_canledar">场馆档期</p>
                <i @click="thisDateMath" class="el-icon-refresh-left"></i>
                <!-- 上个月 -->
                <li @click="PreMonth(myDate,false)">
                    <div class="wh_jiantou1"></div>
                </li>
                <li class="wh_content_li">{{dateTop}}</li>
                <!-- 下个月 -->
                <li @click="NextMonth(myDate,false)">
                    <div class="wh_jiantou2"></div>
                </li>
            </div>

            <!-- 星期 -->
            <div class="wh_content">
                <div class="wh_content_item" :key="ind" v-for="(tag,ind) in textTop">
                    <div class="wh_top_tag">{{tag}}</div>
                </div>
            </div>

            <div class="wh_content">
                <div class="wh_content_item" v-for="(item,index) in list" :key="index" @click="clickDay(item,index)">
                    <div class="wh_item_date" v-bind:class="[{ isClickDate: item.isClickDate},{ jintian: item.jintian},{ hui_isMark: item.dateHui},{ wh_isMark: item.isMark},{wh_other_dayhide:item.otherMonth!=='nowMonth'},{wh_want_dayhide:item.dayHide},{wh_isToday:item.isToday},{wh_chose_day:item.chooseDay},setClass(item)]">
                        {{item.id}}</div>
                </div>
            </div>

        </div>
        <div class="date-color" v-if="token !== null">
            <!-- 231,113,26 -->
            <div><i style="background-color:#FF7100;"></i>不可预约</div>
            <div><i style="background-color:#115dd0;"></i>可挑战（可挑战的也可预约）</div>
            <!-- 131,69,215 -->
        </div>
    </section>
</template>

<script>
import timeUtil from "./calendar";
export default {
    data() {
        return {
            myDate: [],
            list: [],
            historyChose: [],
            dateTop: "",
            dateList: [],
            token:"",
            itemDate: {},
        };
    },
    props: {
        dateKexuan: {
            type: Array,
            default: () => [],
        },
        dateBukexuan: {
            type: Array,
            default: () => [],
        },
        markDate: {
            type: Array,
            default: () => [],
        },
        markDateMore: {
            type: Array,
            default: () => [],
        },
        textTop: {
            type: Array,
            default: () => ["一", "二", "三", "四", "五", "六", "日"],
        },
        sundayStart: {
            type: Boolean,
            default: () => false,
        },
        agoDayHide: {
            type: String,
            default: `0`,
        },
        futureDayHide: {
            type: String,
            default: `2554387200`,
        },
    },
    created() {
        this.intStart();
        this.myDate = new Date();

        this.token = localStorage.getItem('Authorization')
        // console.log(this.token)
    },
    methods: {
        thisDateMath() {
            let ref = false;
            this.$emit("clickDate", ref);
        },
        //可选日期获取
        optional(keyuyue) {},

        //不可选日期获取
        noOpitonal() {},

        intStart() {
            // timeUtil.sundayStart = this.sundayStart;
            timeUtil.getMonthListNoOther(new Date());
        },
        setClass(data) {
            let obj = {};
            obj[data.markClassName] = data.markClassName;
            return obj;
        },
        clickDay: function (item) {
            if (item.isMark == true || item.isToday == true) {
                item.isClickDate = true;
                this.$emit("clickDate", item);
                // this.itemDate = item;
                // this.getLists(this.myDate, item.date);
            }
            // if (item.otherMonth === "nowMonth" && !item.dayHide) {
            //     this.getList(this.myDate, item.date);
            // }
            // if (item.otherMonth !== "nowMonth") {
            //     item.otherMonth === "preMonth" ? this.PreMonth(item.date) : this.NextMonth(item.date);
            // }
        },
        ChoseMonth: function (date, buke, ke, val, isChosedDay = true) {
            // date = timeUtil.dateFormat(date);
            this.myDate = new Date(date);
            // this.$emit("changeMonth", timeUtil.dateFormat(this.myDate));
            if (isChosedDay) {
                // this.getList(this.myDate, date,isChosedDay);
                this.getLists(new Date(), buke, ke, val);
            } else {
                this.getList(this.myDate);
            }
        },
        PreMonth: function (date, isChosedDay = true) {
            date = timeUtil.dateFormat(date);
            this.myDate = timeUtil.getOtherMonth(this.myDate, "preMonth");
            this.$emit(
                "changeMonth",
                timeUtil.dateFormat(this.myDate),
                this.myDate
            );
            if (isChosedDay) {
                this.getList(this.myDate, date, isChosedDay);
            } else {
                this.getList(this.myDate);
            }
        },
        NextMonth: function (date, isChosedDay = true) {
            date = timeUtil.dateFormat(date);
            this.myDate = timeUtil.getOtherMonth(this.myDate, "nextMonth");

            this.$emit(
                "changeMonth",
                timeUtil.dateFormat(this.myDate),
                this.myDate
            );
            if (isChosedDay) {
                this.getList(this.myDate, date, isChosedDay);
            } else {
                this.getList(this.myDate);
            }
        },
        forMatArgs: function () {
            let markDate = this.markDate;
            let markDateMore = this.markDateMore;
            markDate = markDate.map((k) => {
                return timeUtil.dateFormat(k);
            });
            markDateMore = markDateMore.map((k) => {
                k.date = timeUtil.dateFormat(k.date);
                return k;
            });
            return [markDate, markDateMore];
        },
        getLists: function (date, buke, ke, val) {
            //通过接口赋值

            // const [markDate, markDateMore] = this.forMatArgs();
            this.dateTop = `${date.getFullYear()}年${date.getMonth() + 1}月`;
            let arr = timeUtil.getMonthList(this.myDate);
            for (let m = 0; m < arr.length; m++) {
                const e = arr[m];
                for (let n = 0; n < ke.length; n++) {
                    const item = ke[n];
                    if (e.date == item) {
                        (function () {
                            e.isToday = true;
                        })();
                    }
                }
            }
            for (let m = 0; m < arr.length; m++) {
                const e = arr[m];
                for (let n = 0; n < buke.length; n++) {
                    const item = buke[n];
                    if (e.date == item) {
                        (function () {
                            e.isMark = true;
                        })();
                    }
                }
            }
            // let d = new Date();
            // let t = d.getDate();
            // for (let j = 0; j < arr.length; j++) {
            //     const e = arr[j];
            //     if(e.date.split('/')[2]>t){
            //         e.dateHui = true
            //     }
            // }
            arr.map((item) => {
                item.date = this.formDateShow(item.date);
                return item;
            });
            const toDay = this.formDateShow(new Date());
            let thisDade = toDay;
            for (let i = 0; i < arr.length; i++) {
                const e = arr[i];
                if (thisDade > e.date) {
                    e.dateHui = true;
                    e.isMark = false;
                    e.isToday = false;
                }
            }
            if (val) {
                for (let k = 0; k < arr.length; k++) {
                    const e = arr[k];
                    if (e.id == val.id) {
                        e.isClickDate = true;
                    }
                }
            }
            arr.map((item) => {
                item.date = this.timeStampToDate(item.date);
                return item;
            });
            // console.log(arr);
            this.list = arr;
        },
        /**
         * @function 时间戳转化为年月日
         * @param {string} date
         * @returns {number} 时间戳
         * */
        timeStampToDate(timestamp) {
            if (timestamp === 0 || timestamp == null || timestamp == "") {
                return "";
            } else {
                var date = new Date(timestamp);
                var Y = date.getFullYear() + "/";
                var M = date.getMonth() + 1 + "/";
                var D = date.getDate();
                return Y + M + D;
            }
        },
        formDateShow(value) {
            var date = new Date(value);
            var time = Date.parse(date);
            return time;
        },
        getList: function (date, chooseDay, isChosedDay = true) {
            //通过接口赋值
            //  let this.dateKexuan = ["2021/6/2", "2021/6/3", "2021/6/4", "2021/6/5"];
            //  let this.dateBukexuan = ["2021/6/22", "2021/6/23", "2021/6/24", "2021/6/25"];

            const [markDate, markDateMore] = this.forMatArgs();
            this.dateTop = `${date.getFullYear()}年${date.getMonth() + 1}月`;
            let arr = timeUtil.getMonthList(date);
            for (let m = 0; m < arr.length; m++) {
                const e = arr[m];
                for (let n = 0; n < this.dateKexuan.length; n++) {
                    const item = this.dateKexuan[n];
                    if (e.date == item) {
                        (function () {
                            e.isToday = true;
                        })();
                    }
                }
            }

            // for (let i = 0; i < arr.length; i++) {
            //     let markClassName = "";
            //     let k = arr[i];
            //     k.chooseDay = false;
            //     const nowTime = k.date;
            //     const t = new Date(nowTime).getTime() / 1000;
            //     //看每一天的class
            //     for (const c of markDateMore) {
            //         if (c.date === nowTime) {
            //             markClassName = c.className || "";
            //         }
            //     }
            //     //标记选中某些天 设置class
            //     k.markClassName = markClassName;
            //     k.isMark = markDate.indexOf(nowTime) > -1;
            //     // 无法选中某天
            //     k.dayHide = t < this.agoDayHide || t > this.futureDayHide;
            //     if (k.isToday) {
            //         this.$emit("isToday", nowTime);
            //     }
            //     let flag = !k.dayHide && k.otherMonth === "nowMonth";
            //     if (chooseDay && chooseDay === nowTime && flag) {
            //         this.$emit("choseDay", nowTime);
            //         this.historyChose.push(nowTime);
            //         k.chooseDay = true;
            //     } else if (
            //         this.historyChose[this.historyChose.length - 1] ===
            //             nowTime &&
            //         !chooseDay &&
            //         flag
            //     ) {
            //         k.chooseDay = true;
            //     }
            // }


            for (let m = 0; m < arr.length; m++) {
                const e = arr[m];
                for (let n = 0; n < this.dateBukexuan.length; n++) {
                    const item = this.dateBukexuan[n];
                    if (e.date == item) {
                        (function () {
                            e.isMark = true;
                        })();
                    }
                }
            }


            let dangtian = this.dateFormat(new Date());
            for (let k = 0; k < arr.length; k++) {
                const e = arr[k];
                if (e.date == dangtian) {
                    e.jintian = true;
                }
            }

            this.list = arr;
        },
        // format日期
        dateFormat(date) {
            date =
                typeof date === "string"
                    ? new Date(date.replace(/\-/g, "/"))
                    : date;
            return (
                date.getFullYear() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getDate()
            );
        },
    },
    mounted() {
        this.getList(this.myDate);
    },
    watch: {
        markDate: {
            handler() {
                this.getList(this.myDate);
            },
            deep: true,
        },
        markDateMore: {
            handler(val, oldVal) {
                this.getList(this.myDate);
            },
            deep: true,
        },
        agoDayHide: {
            handler(val, oldVal) {
                this.getList(this.myDate);
            },
            deep: true,
        },
        futureDayHide: {
            handler(val, oldVal) {
                this.getList(this.myDate);
            },
            deep: true,
        },
        sundayStart: {
            handler(val, oldVal) {
                this.intStart();
                this.getList(this.myDate);
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
@media screen and (min-width: 460px) {
    .wh_item_date:hover {
        background: #f2f2f2;
        cursor: pointer;
        border-radius: 50%;
        color: #333;
    }
}
* {
    margin: 0;
    padding: 0;
}

.wh_container {
    width: 100%;
}

li {
    list-style-type: none;
}
.wh_top_changge {
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wh_top_canledar {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
}

.wh_top_changge li {
    cursor: pointer;
    display: flex;
    color: #fff;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    height: 47px;
}

.wh_top_changge .wh_content_li {
    cursor: auto;
    color: #333333;
    font-size: 14px;
    margin: 0 4px;
}
.wh_content {
    box-sizing: border-box;
}
.wh_content_all {
    color: #666;
    background-color: #fff;
    width: 100%;
    overflow: hidden;
}

.wh_content {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
}

.wh_content:first-child .wh_content_item_tag,
.wh_content:first-child .wh_content_item {
    color: #ddd;
    font-size: 16px;
}
.wh_content_item {
    /* border-right: 1px solid #dcdfe6; */
    /* border-bottom: 1px solid #dcdfe6; */
}
.wh_content_item:nth-child(7) {
    border-right: 0;
}
.wh_content_item:nth-child(14) {
    border-right: 0;
}
.wh_content_item:nth-child(21) {
    border-right: 0;
}
.wh_content_item:nth-child(28) {
    border-right: 0;
}
.wh_content_item:nth-child(29) {
    border-bottom: 0;
}
.wh_content_item:nth-child(30) {
    border-bottom: 0;
}
.wh_content_item:nth-child(31) {
    border-bottom: 0;
}
.wh_content_item:nth-child(32) {
    border-bottom: 0;
}
.wh_content_item:nth-child(33) {
    border-bottom: 0;
}
.wh_content_item:nth-child(34) {
    border-bottom: 0;
}
.wh_content_item:nth-child(35) {
    border-bottom: 0;
    border-right: 0;
}
.wh_content_item,
wh_content_item_tag {
    font-size: 15px;
    width: 14.2%;
    text-align: center;
    color: #555;
    position: relative;
}
.wh_content_item {
    height: 50px;
}

.wh_top_tag {
    height: 60px;
    line-height: 60px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
}

.wh_item_date {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 10px auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
}

.wh_jiantou1 {
    width: 12px;
    height: 12px;
    border-top: 2px solid #666;
    border-left: 2px solid #666;
    transform: rotate(-45deg);
}

.wh_jiantou1:active,
.wh_jiantou2:active {
    border-color: #ddd;
}

.wh_jiantou2 {
    width: 12px;
    height: 12px;
    border-top: 2px solid #666;
    border-right: 2px solid #666;
    transform: rotate(45deg);
}

.wh_content_item > .wh_isMark {
    margin: auto;
    border-radius: 50%;
    background-color: #115dd0;
    /* opacity: 0.7; */
    color: #fff;
    z-index: 2;
}
.wh_content_item .wh_other_dayhide {
    color: #bfbfbf;
}
.wh_content_item .wh_want_dayhide {
    color: #bfbfbf;
}
.wh_content_item .wh_isToday {
    background-color: #ff7100;
    color: #fff;
    border-radius: 50%;
}
.wh_content_item .wh_chose_day {
    background-color: #115dd0;
    color: #fff;
    border-radius: 50%;
}
.date-color {
    position: absolute;
    right: 0;
    bottom: 30px;
    margin-top: 40px;
    display: flex;
    align-items: center;
}
.date-color div {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #847c7c;
    margin-right: 20px;
}
.date-color div i {
    width: 10px;
    height: 10px;
    /* border-radius:50%; */
    margin-right: 10px;
}
.wh_content_item > .hui_isMark {
    background: #fff !important;
    color: #bfbfbf !important;
}
.wh_content_item > .isClickDate {
    background: #66b1ff !important;
    color: #fff !important;
}
.wh_content_item > .jintian {
    border-bottom: 3px solid #66b1ff;
    /* background: #66b1ff !important; */
    /* color: #fff !important; */
}
</style>