import ajax from "./ajax"

// 根据id获取场馆列表
export function getVenueInfoByAreaId(params){
    return ajax({
        url:'/api/venue-info/getVenueInfoByAreaId',
        method:'get',
        params
    })
}

//根据场馆id获取场馆信息
export function getVenueInfoById(id){
    return ajax({
        url:`/api/venue-info/getVenueInfoById/${id}`,
        method:'get'
    })
}

//日历
export function getBookingTimeByVenueId(params){
    return ajax({
        url:'/api/booking/getBookingTimeByVenueId',
        method:'get',
        params
    })
}

//推荐场馆
export function getRecommendVenue(params){
    return ajax({
        url:'/api/venue-info/getRecommendVenue',
        method:'get',
        params
    })
}

//活动类型下拉框
export function listActivityType(params){
    return ajax({
        url:'/api/activity-type/listActivityType',
        method:'get',
        params
    })
}

//根据活动类型获取字段
export function listFieldByTypeId(params){
    return ajax({
        url:'/api/activity-filed/listFieldByTypeId',
        method:'get',
        params
    })
}

//获取主办方信息
export function getSponsor(params){
    return ajax({
        url:'/api/company/getSponsor',
        method:'get',
        params
    })
}

//确认提交
export function saveBooking(data){
    return ajax({
        url:'/api/booking/saveBooking',
        method:'post',
        data
    })
}

//确认提交
export function listActivityByDate(params){
    return ajax({
        url:'/api/booking/listActivityByDate',
        method:'get',
        params
    })
}

//选择预约时间
export function checkStartTime(params){
    return ajax({
        url:'/api/booking/checkStartTime',
        method:'get',
        params
    })
}

//选择活动时间
export function checkActivityTime(params){
    return ajax({
        url:'/api/booking/checkActivityTime',
        method:'get',
        params
    })
}


//日期标签
export function listOnlyActivityByDate(params){
    return ajax({
        url:'/api/booking/listOnlyActivityByDate',
        method:'get',
        params
    })
}