<template>
    <div class="detail">
        <div class="detail-center">
            <div class="detail-left">
                <!-- 日历 -->
                <div class="detail-exhibit">
                    <div class="exhibit-modular">
                        <p class="exhibit-modular-title">{{venueInfo.name}}</p>
                        <div class="exhibit-modular-address">
                            <p>{{venueInfo.address}}</p>
                            <span class="grand-hall" v-if="venueInfo.type == 0">#小馆#</span>
                            <span class="grand-hall" v-if="venueInfo.type == 1">#大馆#</span>
                            <span class="grand-hall" v-if="venueInfo.type == 2">#体育场#</span>
                            <span class="grand-hall" v-if="venueInfo.type == 3">#篮球馆#</span>
                        </div>
                        <div class="detail-thumbnail">
                            <img :src="venueInfo.thumbnail" alt="">
                        </div>
                        <div class="exhibit-modular-phone">
                            <p>热线电话：</p>
                            <div class="phone-submit">
                                <div>
                                    <span>{{venueInfo.telPhone}}</span>
                                    <span>{{venueInfo.mobilePhone}}</span>
                                </div>
                                <i>（工作时间为9:00-17:30）</i>
                            </div>
                        </div>
                    </div>
                    <div class="exhibit-calendar">
                        <div class="exhibit-calendar-button" v-if="status == 0 && token !== null">
                            <button type="button" @click="goReserve">立即预订</button>
                        </div>
                        <div class="exhibit-calendar-button button-grey" v-else>
                            <button type="button" @click="goSubmit">立即预订</button>
                        </div>
                        <div class="calendar-center">
                            <div v-if="tokenType == 0 || status == 2 || status == 3" class="calendar-black" @click="goLogin"></div>
                            <Calender v-if="tokenType == 0 || status == 2 || status == 3"/>
                            <Calender v-else @changeMonth="changeMonth" @clickDate='clickDate' ref="calender" :key="dateKey" :markDate='marked' :dateKexuan='dateKexuan' :dateBukexuan='dateBukexuan'/>
                            <el-dialog :title="titleyu" :visible.sync="dialogVisible" width="30%" :show-close="true" style="height:600px;border-radius:10px">
                                <!-- <el-dialog :title="当前有X挑战"  :visible.sync="dialogVisible" width="30%" :show-close="true" style="border-radius:8px"> -->
                                <!-- <span>这是一段信息</span> -->
                                <div class="box-info">
                                    <div v-for="(item,index) in bookingList" :key="index">
                                        <p v-if="item.status == 2">
                                            <span v-if="item.challenged == false">已有1家企业待签约</span>
                                            <span v-if="item.challenged == true">已有1家企业可挑战</span>
                                        </p>
                                        <p v-if="item.status == 6">已有1家企业已签约</p>
                                        <span class="type">#{{item.type}}#</span>
                                        <!-- </div> -->
                                    </div>
                                </div>

                                <!-- <span slot="footer" class="dialog-footer">
                                    <el-button @click="handleClose">取 消</el-button>
                                </span> -->
                            </el-dialog>
                        </div>
                    </div>
                </div>
                <!-- 场馆介绍 -->
                <div class="detail-venue">
                    <p class="venue-title">场馆介绍</p>
                    
                    <div class="venue-center">
                        <div class="shoppingInfo" v-html="venueInfo.description"></div>
                    </div>
                </div>
            </div>
            <!-- 推荐场馆 -->
            <div class="detail-right">
                <div class="recomm-title">
                    <p>推荐场馆</p>
                </div>
                <div class="recomm-list" v-for="(item,index) in venueInfoList" :key="index" @click="goDetail(index)">
                    <img :src="item.thumbnail" alt="">
                    <div class="recomm-list-bottom">
                        <div>
                            <h2>{{item.name}}</h2><span class="grand-hall">#{{item.typeName}}#</span>
                        </div>
                        <p>{{item.address}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getVenueInfoById,
    getBookingTimeByVenueId,
    getRecommendVenue,
    listActivityByDate,
    listOnlyActivityByDate
} from "../../api/venue";
import Calender from "../../components/Calender";
import { getCompanyInfo } from "../../api/personal"
export default {
    components: {
        //组件大驼峰命名
        Calender,
    },
    data() {
        return {
            titleyu: "",
            gridData: [],
            id: "",
            venueInfo: {},
            dateKexuan: [], //不可预约
            dateBukexuan: [], //可预约
            marked: [], // 被选中的日期
            yearMonth: "", // 切换的月份
            dateKey: 1,
            nowDate: "",
            day: "",
            venueInfoList: [],
            dialogVisible: false,
            bookingList: [],
            token:"",
            status:"",
            reserve_id:"",
            companyInfo:{},
            tokenType:""
        };
    },
    created() {
        this.id = this.$route.params.id;
        this.getVenueInfoById();
        //获取当天时间
        // this.addDate();
        
        // console.log(this.token)
        // if(this.token == null){
        //     console.log('calender')
        // }else{
        //     this.addDate();
        // }

        // this.status = localStorage.getItem('status')
        // console.log(this.status)

        this.getCompanyInfo()
    },
    mounted() {},
    watch: {
        // dateKexuan:{
        //     handler(val,oldVal){
        //         ++this.dateKey;
        //         // this.$refs.calender.getList(this.day)
        //     },
        //     deep: true,
        // },
        // dateBukexuan:{
        //     handler(val,oldVal){
        //         ++this.dateKey;
        //         // this.$refs.calender.getList(this.day)
        //     },
        //     deep: true,
        // },
    },
    mounted() {},
    methods: {
        goReserve() {
            console.log(this.venueInfo)
            this.reserve_id = this.venueInfo.id
            console.log(this.reserve_id)
            this.$router.push({
                path: "/reserve",
                query: {
                    venueInfo: this.venueInfo,
                    reserve_id:this.reserve_id
                },
            });
        },

        getCompanyInfo(){
            getCompanyInfo().then((res) => {
                if(res.data.code == 20000){
                    this.companyInfo = res.data.data.companyInfo
                    this.status = res.data.data.companyInfo.status
                    this.addDate();
                    this.tokenType = 1 
                }else{
                    console.log('不展示日历')
                    this.$message.error(res.data.message)
                    this.tokenType = 0
                }
            })
        },

        goSubmit(){
            if(this.status == 2){
                this.$message.error("企业审核中")
            }else if(this.status == 3){
                this.$message.error("企业已驳回")
            }
        },

        goLogin() {
            if(this.token == null){
                this.$confirm('请登录后或注册', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$router.push("/login")
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });    
                });
            }else if(this.status == 2){
                this.$message.error("企业审核中")
            }else if(this.status == 3){
                this.$message.error("企业已驳回")
            }


            // this.$router.push("/login")
        },

        handleClose(done) {
            done();
        },

        clickDate(val) {
            // this.datetime = val.date;
            if (val) {
                this.dialogVisible = true;
                // this.listActivityByDate(val.date);
                this.listOnlyActivityByDate(val.date)
                this.$refs.calender.getLists(
                    this.day,
                    this.dateBukexuan,
                    this.dateKexuan,
                    val
                );
            } else {
                this.dateBukexuan = [];
                this.dateKexuan = [];
                this.addDate();
                this.$refs.calender.ChoseMonth(
                    new Date(),
                    this.dateBukexuan,
                    this.dateKexuan,
                    val
                );
                this.$refs.calender.getLists(
                    new Date(),
                    this.dateBukexuan,
                    this.dateKexuan,
                    val
                );
            }
        },

        // listActivityByDate(date) {
        //     listActivityByDate({ 
        //         datetime: date, 
        //         id: this.id,
        //         status:-1 
        //     }).then((res) => {
        //         if (res.data.code == 20000) {
        //             this.bookingList = res.data.data.bookingList;
        //             let lengths = 0;
        //             lengths = this.bookingList.length;
        //             this.titleyu = `已有${lengths}家企业申请`;
        //         }
        //     });
        // },

        listOnlyActivityByDate(date){
            listOnlyActivityByDate({ 
                datetime: date, 
                id: this.id
            }).then((res) => {
                if (res.data.code == 20000) {
                    this.bookingList = res.data.data.bookingList;
                    let lengths = 0;
                    lengths = this.bookingList.length;
                    this.titleyu = `已有${lengths}家企业申请`;
                }
            });
        },

        getVenueInfoById() {
            getVenueInfoById(this.id).then((res) => {
                if (res.data.code == 20000) {
                    this.venueInfo = res.data.data.venueInfo;
                    this.getRecommendVenue();
                }
            });
        },

        //获取当月月份和日期
        addDate() {
            console.log(111)
            this.nowDate = new Date();
            let date = {
                year: this.nowDate.getFullYear(),
                month: this.nowDate.getMonth() + 1,
                date: this.nowDate.getDate(),
            };
            let systemDate = date.year + "/" + date.month;
            this.yearMonth = systemDate;
            this.day = this.nowDate;
            //获取当前选择时间的预约数据
            this.getBookingTimeByVenueId();
        },

        // 获取切换日历
        changeMonth(myDate, date) {
            // console.log(111)
            this.day = date;
            const dates = myDate.replace(/\//g, "/");
            
            console.log(dates)
            var day_length = dates.length
            var day_sub = dates.substring(6,7)


            // 2021/1/7  8    2021/1/12   9   2021/10/1  9    2021/10/10   10

            if(day_length == 8){
                let yearM = dates.substring(0, dates.length - 2);
                this.yearMonth = yearM; // 去除后俩位
                console.log(this.yearMonth)
            }else if(day_length == 10){
                let yearM = dates.substring(0, dates.length - 3);
                this.yearMonth = yearM; // 去除后三位
                console.log(this.yearMonth)
            }else if(day_length == 9){
                if(day_sub == '/'){
                    let yearM = dates.substring(0, dates.length - 3);
                    this.yearMonth = yearM; // 去除后俩位
                    console.log(this.yearMonth)
                }else{
                    let yearM = dates.substring(0, dates.length - 2);
                    this.yearMonth = yearM; // 去除后俩位
                    console.log(this.yearMonth)
                }
            }
            
           
            
            this.getBookingTimeByVenueId();
        },

        //日期
        getBookingTimeByVenueId() {
            console.log(222)
            getBookingTimeByVenueId({
                id: this.id,
                date: this.yearMonth,
            }).then((res) => {
                if (res.data.code == 20000) {
                    this.dateBukexuan = res.data.data.dateList.challengedList;
                    this.dateKexuan = res.data.data.dateList.signedList.concat(res.data.data.dateList.waitSignedList);
                    // this.waitSignedList = res.data.data.dateList.waitSignedList;
                    // console.log(this.dateKexuan)
                    this.$refs.calender.getLists(
                        this.day,
                        this.dateBukexuan,
                        this.dateKexuan,
                        // this.waitSignedList
                    );
                }
            });
        },

        //推荐场馆
        getRecommendVenue() {
            getRecommendVenue({
                id: this.venueInfo.venueAreaId,
                venueId: this.venueInfo.id,
            }).then((res) => {
                if (res.data.code == 20000) {
                    this.venueInfoList = res.data.data.venueInfoList;
                }
            });
        },

        goDetail(index) {
            this.id = this.venueInfoList[index].id;
            // console.log(this.id);
            this.$router.push({
                name: "detail", 
                params: {
                    id:this.id,
                }
            });
            this.dateBukexuan = []
            this.dateKexuan = []
            this.getBookingTimeByVenueId()

            this.getVenueInfoById();
            
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__headerbtn .el-dialog__close {
    color: #fff;
}
.box-info {
        width: 100%;
    height: 100%;
    overflow: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    div {
       margin: 5px 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid #f0d0b6;
        padding-bottom: 5px;
        .type {
            color: #ff7100;
        }
        .name {
            
            margin-left: 50px;
        }
    }
}
.detail {
    width: 100%;
    background-color: #f8f8f8;
    .detail-center {
        width: 1200px;
        margin: auto;
        margin-top: 20px;
        display: flex;
        .detail-left {
            width: 880px;
            background-color: #ffffff;
            padding: 30px;
            margin-bottom: 100px;
            .detail-exhibit {
                width: 100%;
                height: 487px;
                border-bottom: 1px solid #d8d8d8;
                display: flex;
                position: relative;
                .exhibit-modular {
                    width: 480px;
                    .exhibit-modular-title {
                        color: #333333;
                        font-size: 20px;
                        font-weight: bold;
                    }
                    .exhibit-modular-address {
                        display: flex;
                        align-items: center;
                        margin-top: 4px;
                        margin-bottom:15px;
                        p {
                            color: #bbbbbb;
                            font-size: 14px;
                            margin-right: 15px;
                        }
                    }
                    .detail-img{
                        width: 100%;
                        position:absolute;
                        left:0;
                        top:70px;
                        img{
                            width:100%;
                            height: 308px;
                            border-radius: 8px;
                            margin: 15px 0 30px 0;
                        }
                    }
                    .detail-thumbnail{
                        width:100%;
                        height:308px;
                        img{
                            width:100%;
                            height: 308px;
                            border-radius: 8px;
                            // margin: 15px 0 30px 0;
                        }
                    }
                    .exhibit-modular-phone {
                        display: flex;
                        // align-items: center;
                        margin-top:20px;
                        p {
                            color: #999999;
                            font-size: 16px;
                        }
                        .phone-submit{
                            display:flex;
                            flex-direction: column;
                            span {
                                color: #333333;
                                font-size: 18px;
                                font-weight: bold;
                                margin-right: 10px;
                            }
                            i{
                                color:#999999;
                                font-size:14px;
                                margin-top:10px;
                            }
                        }
                    }
                }
                // 日历
                .exhibit-calendar {
                    margin-left: 20px;
                    width: 380px;
                    position: relative;
                    .exhibit-calendar-button {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        button {
                            width: 110px;
                            height: 40px;
                            background: linear-gradient(
                                270deg,
                                #ff6100 0%,
                                #ffb800 100%
                            );
                            border-radius: 6px;
                            color: #ffffff;
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: none;
                        }
                    }
                    .button-grey{
                        button{
                            background:#f2f2f2;
                            color:#999999;
                        }
                    }
                    .calendar-center {
                        width: 100%;
                        // filter: blur(2px);
                        .calendar-black{
                            // background: url(/img/home-bg.ba6347ae.jpg) no-repeat;
                            // background: rgb(0, 0, 0,0.5);
                            background: rgba(255, 255, 255, 0.8);
                            width: 102%;
                            height: 340px;
                            background-size: 100% 100%;
                            position: absolute;
                            z-index: 999;
                            top: 38px;
                            left: -6px;
                            margin-top: 5px;
                            border-radius: 10px;
                            -moz-filter: blur(4px);
                            -ms-filter: blur(4px);
                            filter: blur(4px);
                            cursor: pointer;
                        }
                    }
                }
            }
            .detail-venue {
                margin-top: 20px;
                padding-bottom: 70px;
                .venue-title {
                    color: #333333;
                    font-size: 20px;
                    font-weight: bold;
                }
                .venue-center {
                    width: 100%;
                    margin-top: 20px;
                    .shoppingInfo {
                        // width: 747px;
                        p{
                            display:flex;
                            align-items: center;
                            flex-direction: column;
                        }
                    }
                    div {
                        color: #333333;
                        font-size: 14px;
                        line-height: 30px;
                        text-indent: 2em;
                        .para {
                            line-height: 26px;
                            img {
                                width: 747px !important;
                                height: 322px;
                                border-radius: 8px;
                                margin: auto;
                                margin: 20px 0;
                            }
                        }
                    }
                }
            }
        }
        // 推荐场馆
        .detail-right {
            width: 300px;
            margin-left: 20px;
            .recomm-title {
                width: 100%;
                height: 30px;
                border-bottom: 2px solid #d8d8d8;
                display: flex;
                p {
                    color: #333333;
                    font-weight: bold;
                    height: 30px;
                    font-size: 16px;
                    border-bottom: 2px solid #ff7100;
                }
            }
            .recomm-list {
                width: 100%;
                margin-top: 15px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 192px;
                    border-radius: 6px 6px 0px 0px;
                }
                .recomm-list-bottom {
                    width: 100%;
                    height: 74px;
                    background-color: #ffffff;
                    border-radius: 0px 0px 6px 6px;
                    margin-top: -3px;
                    padding: 0 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    div {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        h2 {
                            color: #282828;
                            font-size: 16px;
                            font-weight: 600;
                        }
                        span {
                            width: 52px;
                            height: 18px;
                            background-color: #ffebdb;
                            border-radius: 3px;
                            color: #ff7100;
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    p {
                        padding-top: 6px;
                        color: #bbbbbb;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    /deep/ .el-dialog__header{
        text-align: center;
    }
}
</style>